import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "modals-communicate-information-via-a-secondary-window-and-allow-the-user-to-maintain-the-context-of-a-particular-task"
    }}>{`Modals communicate information via a secondary window and allow the user to maintain the context of a particular task.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Format</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`Modals interrupt user workflow by design. They are most effective when a task must be completed before a user can continue. While effective when used correctly, modals should be used sparingly to limit disruption to a user experience.`}</p>
    <h4 {...{
      "id": "dismissal"
    }}>{`Dismissal`}</h4>
    <p>{`Modals may be dismissed in 3 ways:`}</p>
    <ul>
      <li parentName="ul">{`Using the “✕” in the upper right-hand corner of the modal`}</li>
      <li parentName="ul">{`Pressing the `}<inlineCode parentName="li">{`ESC`}</inlineCode>{` key`}</li>
      <li parentName="ul">{`Clicking or touching outside of the modal`}</li>
    </ul>
    <h2 {...{
      "id": "format"
    }}>{`Format`}</h2>
    <p>{`The modal is composed of three distinct zones: A header, the body, and a footer. Components (eg. data table, form, progress indicator) can occupy the full width of the modal.`}</p>
    <h4 {...{
      "id": "header"
    }}>{`Header`}</h4>
    <p>{`The header of your modal should mirror the action that launched the modal. Headers must include a close button “✕” in the upper right-hand corner of the modal. You can also include an optional label above your header text. This is an opportunity to offer additional context.`}</p>
    <h4 {...{
      "id": "body"
    }}>{`Body`}</h4>
    <p>{`A modal should have minimal body content. Components that may be used in modals include: form fields, text area, select, and radio buttons. Text, including the paragraph component, should only be 75% of the modal’s width.`}</p>
    <h4 {...{
      "id": "footer"
    }}>{`Footer`}</h4>
    <p>{`The footer area of a modal typically contains either one or two `}<a parentName="p" {...{
        "href": "/components/button"
      }}>{`buttons`}</a>{`. Do not include three buttons in the footer of your modal. If you need to include a “help” or other non-primary action, include it as a link in the modal’s body.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.44565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABhklEQVQoz6WSTU+DQBCG2fiVmNgY9Ud5M6bpyYs2tSd/hud6MPHQX9GTGOMVDuXsrVpKqVIQFxbKWlkYZ7cfetGYdJIn7+zAPOwBTcOq1WraqmVZ1qxhjC2HAEB+o91uE8MwSLfbJZPJhFBKfz77diVJspBtB0HQGY/Hlu/7hud5Jp5N13XNKIrMLMvMNE0VuGjinoFphWHY4ZxX5kIihWQurOBygIDjOGDbtqLX6wF+BFAKcRwDCqEoCsjzHGThLMDZvnQoF42Xwh1nOLIHjgtIbjuu6A+GAmfi6XkgXj1f+G/vIgipwB1BoyRnaQZRnNg0YnvSoVyQs6Vw+PToZLEHPPaK9H0EE/oCKR1BFr2qXuY09QEEA9wrALhMB1FC5WrpXAkvO3zn+v7j+eZhCq1bPkU+f+NKx9T59OoO39V5H1HCmesElFA7gop2CoxcAGh15OyPXNBAToAhB8ohXVvNUvW7F+X6WqM8JPWyutksj7f+RxU5RDalY+HSNs5h5R8bb6ziC5uwrUWTIMqCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of modal with one button.",
            "title": "Example of modal with one button.",
            "src": "/static/5d3eeaee19e6de521c2ec1e9fa2f04ab/fb070/modal-usage-4a.png",
            "srcSet": ["/static/5d3eeaee19e6de521c2ec1e9fa2f04ab/d6747/modal-usage-4a.png 288w", "/static/5d3eeaee19e6de521c2ec1e9fa2f04ab/09548/modal-usage-4a.png 576w", "/static/5d3eeaee19e6de521c2ec1e9fa2f04ab/fb070/modal-usage-4a.png 1152w", "/static/5d3eeaee19e6de521c2ec1e9fa2f04ab/c3e47/modal-usage-4a.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.902173913043484%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAB9UlEQVQoz6WSzWoaURiG57QhxGkNtYKISGnGbrp10ayGXIOrUggpbcii3Um7KG2IUMSFl+AFBIIUAuJOl3Ez16CdRNTMj/PrqOPMnK/fHLWFbnPg4f14mXnO4cxwHK5SqcQ9dEmStB4cx/lbAgD5n2azSdrtNul2u6TVapFOp0Om0ymxbZtQStkzjUbjn8t13a2M1zTtWlEUSde1m8lk0lNVted5Xm8WM5utZ0x8J+YGBRLKrxeLxf5GSGIh2Qj3ZVnWRqMRYEK/34d4tm0LTNPAtMEwDLAsC4IgYMQLew03SccO5jJNcytMDodDOZYNBoOgP/gdjsb3oaLq4eReCxXNCFVE043QtBzEDRx3DqbtyoblPI8d2BF2b1vhcrm88/0VzD0v8pYRnQdAZyugHhKtPAqRQyF0KQRxOiHAArC7Q5gQk3CVSoUJy+Vyslqt3tbrdajVaiH2lHFxTi9+1un3S52e/wroj+YS8em3Kz/8ekXhy6V/izAhJuESiQTZfORkKpUaZzIZSKfTwPM8JPgnwO/twO6zl8C9VYE7A+A+IB+R98gx8g7GCLtDTMIJgsDmYrH4OJ/PH2az2aNcLidiLwqFV6Jw8EI8eP1G3DuZiNwpiI9OI4SKu2dUTH6mR08/0UNkJ3Zgro9WKBQe/GPjyVn8AS7VjwIE1EshAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of modal with two buttons.",
            "title": "Example of modal with two buttons.",
            "src": "/static/6c65ccb91af6123ab6461fd299b121a0/fb070/modal-usage-4b.png",
            "srcSet": ["/static/6c65ccb91af6123ab6461fd299b121a0/d6747/modal-usage-4b.png 288w", "/static/6c65ccb91af6123ab6461fd299b121a0/09548/modal-usage-4b.png 576w", "/static/6c65ccb91af6123ab6461fd299b121a0/fb070/modal-usage-4b.png 1152w", "/static/6c65ccb91af6123ab6461fd299b121a0/c3e47/modal-usage-4b.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h3 {...{
      "id": "transactional-modal"
    }}>{`Transactional modal`}</h3>
    <p>{`Transactional modals are used to validate user decisions or to gain secondary confirmation from the user. Typically, the modal requests either a ‘yes’ or ‘no’ response.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.902173913043484%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABwklEQVQoz6WSz2riQBzHZ1jZrulaVntUSmP2slfB7Sn0NXZLe2gPhV4rhaW0Cosg9BF8AKH00peoB/MOmkSN/5JGE1GXJPPrb8bospe9dODD9zc/Zj6ZTEIIjmKxSN47NE1bF57nbZsAQP/HYDCgrVaLrlYr6rrutl+v1/+6fN/fyKTRaPRsWZY2Ho9fhsNhE+eCfr/f5HNc25zP503cyOsXTM1xnOflcrkXCykX0li4p+v6pNvtQqfTAazBNE2R7XZb1HgqWCwWEEURBEEAfMxmswk+ZJ87hIsfPRam8JX0Xq/HNwdIaBiGwLbt0HmdhhPHDW1Md+pzAs9fgDvz9depl+EO7FFxbxshYsJ6RAj7h2jOIPQwt4QAS1zpmYgQYlJSqVSEsFQqparVqlGr1aBcLofYZ4LyHbv//cBuGzb79RSw68aKlRp/OOHNIwNMAxFCTEqSySSNP3IqnU5bmUwGsAeSJEFS2gXpUwI+fjkE8mMM5AKA/EROYk7F3ELEHWJSks/nRV0oFD7kcrmjbDZ7rCiKin01r3xVFflAlb99V3fOBmriEtTUVaR+vmIbjpEjJMEdmOujybL87h+bnIOIN91NkZCdAcdVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "transactional modal",
            "title": "transactional modal",
            "src": "/static/214ec26dfb9018366c29fa5accdbc21a/fb070/modal-usage-1.png",
            "srcSet": ["/static/214ec26dfb9018366c29fa5accdbc21a/d6747/modal-usage-1.png 288w", "/static/214ec26dfb9018366c29fa5accdbc21a/09548/modal-usage-1.png 576w", "/static/214ec26dfb9018366c29fa5accdbc21a/fb070/modal-usage-1.png 1152w", "/static/214ec26dfb9018366c29fa5accdbc21a/c3e47/modal-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h3 {...{
      "id": "input-modal"
    }}>{`Input modal`}</h3>
    <p>{`Modals used in this case include input areas that the user may interact with. These may include but are not limited to forms, dropdowns, selectors, and links.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.1141304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB60lEQVQoz6WTz27TQBCHvRUiBZE/0FtFkdzcIkE4ceectkgRKIBsJAvlxAFBHqSt4JRLniBSX6K5+Bb1HLVVcBo5thPHcWy89vDzOimBa0f6NJv17LczliNJiFarJd01dF3PFq7r3m4SEVvT6XRYt9tl/X6fDQYDtlwuGWoF8/mcTafT29p2u/3XhYdr2cPJZHJmmqZu2/b5YrHopXie10NND8Uib6zPkXXLss5wWWElZKmQrYSF0WgEn0nD4ZAgJ8MwaDweUxiGlCQJcc4pjmNBFEWUxmw2M3HpTuoQLmFFoCiPzi4xCjmOE2HNcTtPM/b4zHU5Dm8SYQLC+UuInmQdQojDQoiCPA5eB0FAuDH2fV90lhIEIIz+J07QoecH16OxKYTOFML0pa5GzoMryoKD+F98sNgENSFR4l1R6AghcUyrKIoQ1uv1vKZpv5rNJqmqKlDUT6QqH+ij9oXeHlv05gfRwQmnw9NY5KOf+H382zg65eIdHp5ETMrlcmz11TwqFosXpVLJw54F7Nz2A3v7/pZ9r/DMlt4ZtqSQLTUSW3pPKRbwwIXUoMfC0MC01WpVrGu1GiuXy3v7COzJGS/l6vOK/OLVa7n4+Ube+Ury0+9c3v0Wr9kHe2ArdSBnrVUqlTv/UyQtEekP5DzOrf/UdyUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "input modal",
            "title": "input modal",
            "src": "/static/d74a33c81f5eb02c9d7e611f9d363bf3/fb070/modal-usage-2.png",
            "srcSet": ["/static/d74a33c81f5eb02c9d7e611f9d363bf3/d6747/modal-usage-2.png 288w", "/static/d74a33c81f5eb02c9d7e611f9d363bf3/09548/modal-usage-2.png 576w", "/static/d74a33c81f5eb02c9d7e611f9d363bf3/fb070/modal-usage-2.png 1152w", "/static/d74a33c81f5eb02c9d7e611f9d363bf3/c3e47/modal-usage-2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h3 {...{
      "id": "passive-modal"
    }}>{`Passive modal`}</h3>
    <p>{`The passive modal is a style of notification. Passive modals are highly disruptive to a user experience, and should only be used if a user must address something immediately.`}</p>
    <p>{`Passive modal notifications are persistent on-screen. Users must either engage with or dismiss the notification.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.92391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABRElEQVQoz6WSTW7CQAyFZ7ZVi9R2gcJNuFfOkkVO0RuwoptIqGsSAvmHkIjwIyI0EPeNSwKV2DHSJ+fJ9hvjQQgc0zTFs8dxnL+P3W7HcTgcCiKSLZZlydFoJCeTiUySRAZBwMxmM1kUhayqSjZNw7W2bd+89vs9CyRfyrL8QrGz2WzGx+Px+3A4MKh5xBgGju6p67p3NZTaUGqBm3pRFK2zLCNMRGmaMiim8/lMSqmOVuuz3W7XuPRTe7DXneFbHMdzz/NoOp2egHJdV0Er3/c54udyzPNcweQECP1zTPbx0BATpcvlkrArwrSEC2ixWHAMw5CwDsLutAlhJXS5XAhmGfS/CcVVvCL5AyqwAnkLchwxEXPVK8QK6J73boftK+tXQ7IPBsBAY4fWGtQYeDADe2ON3gHos5G4PbBAwdP/w3awX0cPqPk5WGETAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Passive modal",
            "title": "Passive modal",
            "src": "/static/6a4abc3c2ad68b9562cb5dd64119c155/fb070/modal-usage-3.png",
            "srcSet": ["/static/6a4abc3c2ad68b9562cb5dd64119c155/d6747/modal-usage-3.png 288w", "/static/6a4abc3c2ad68b9562cb5dd64119c155/09548/modal-usage-3.png 576w", "/static/6a4abc3c2ad68b9562cb5dd64119c155/fb070/modal-usage-3.png 1152w", "/static/6a4abc3c2ad68b9562cb5dd64119c155/c3e47/modal-usage-3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      